import { HttpClient } from "../HttpClient";
/**
 * * Get Current
 */
export const GetCurrent = async (userId) => {
  try {
    const url = userId
      ? `api/users/Current?userId=${userId}`
      : "api/users/Current";
    const { data } = await HttpClient.get(url);
    return await Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
